import React, { useCallback, useEffect, useRef, useState } from 'react';
import useSound from 'use-sound';
import "./MainScreen.css"
import Simulator from './components/simulator/Simulator';
import Timeline from './components/timeline/Timeline';
import Controls from './components/controls/Controls';
import DownloadDialog from './components/download-dialog/DownloadDialog';
import SettingsDialog from './components/settings-dialog/SettingsDialog';
import DonationButton from './components/donation-button/DonationButton';
import axios from "axios";
import ConnectButton from './components/connect-button/ConnectButton';
import ReactGA from "react-ga4";
import help from "../../../assets/images/icons/help.svg";
import HelpDialog from './components/help-dialog/HelpDialog';
import beep1Sound from '../../../assets/sounds/beep1.mp3';
import beep2Sound from '../../../assets/sounds/beep2.mp3';


// eslint-disable-next-line no-undef
const handy = Handy.init();
const baseURL = "https://api.funscript.cz";

// eslint-disable-next-line no-unused-vars
const isTouchDevice = () => ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);

export default function MainScreen() {
  const [playing, setPlaying] = useState(false);
  const [paused, setPaused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [keyboardlMode, setKeyboardMode] = useState(false);
  const [countDownLabel, setCountDownLabel] = useState();
  const [duration, setDuration] = useState(60000);
  const [offset, setOffset] = useState(-200);
  const [speed, setSpeed] = useState(1);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false)
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false)
  const [helpDialogOpen, setHelpDialogOpen] = useState(false)
  const [handyEnabled, setHandyEnabled] = useState(false);
  const [handyConnected, setHandyConnected] = useState(false);
  const [videoSource, setVideoSource] = useState();
  const [playBeep1] = useSound(beep1Sound);
  const [playBeep2] = useSound(beep2Sound);

  // eslint-disable-next-line no-unused-vars
  const [currentT, setCurrentT] = useState(0);
  const [currentV, setCurrentV] = useState(0);

  const savedCallback = useRef();
  const videoPlayerElementRef = useRef();
  const handyUiElementRef = useRef();
  const t = useRef(0);
  const lastValue = useRef(0);
  const timestamps = useRef([]);
  const dataArray = useRef([]);
  const mainFrames = useRef([]);


  let intervalRef = useRef();
  const timeUnit = 100;

  const startInterval = () => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => savedCallback.current(), timeUnit / speed)
  }
  const stopInterval = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  }

  const increaseT = useCallback(() => {
    const currentTRounded = parseInt(t.current / timeUnit) * timeUnit
    const newCurrentT = currentTRounded + timeUnit;
    t.current = newCurrentT;
    if (newCurrentT % 1000 === 0) { setCurrentT(newCurrentT); }
    console.log(newCurrentT);
    if (newCurrentT > duration) {
      stopPlaying()
      stopRecording()
      rewind()
      return;
    }
    if (playing) {
      let currentTimestampIndex = timestamps.current.indexOf(newCurrentT)
      if (dataArray.current[currentTimestampIndex] != null) {
        setCurrentV(dataArray.current[currentTimestampIndex])
      }
    }
    let nextTimestampIndex = timestamps.current.indexOf(newCurrentT + timeUnit)
    if (recording && nextTimestampIndex > -1) {
      timestamps.current.splice(nextTimestampIndex, 1)
      dataArray.current.splice(nextTimestampIndex, 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, recording, playing, speed])

  useEffect(() => { savedCallback.current = increaseT }, [increaseT]);



  useEffect(() => {
    const lastT = parseInt(duration)
    dataArray.current = [0, 0];
    timestamps.current = [0, lastT];
    mainFrames.current = [0, lastT];
    setCurrentT(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getCsvScriptReplayData() {
    return new Promise(async (resolve) => {
      const fullSizeData = [];
      fullSizeData[0] = 0;
      fullSizeData[(parseInt(duration / timeUnit) - 1)] = 0;
      for (let i = 0; i < duration; i += timeUnit) {
        const index = timestamps.current.indexOf(i)
        if (index > -1) {
          fullSizeData[i / timeUnit] = dataArray.current[index];
        } else {
          fullSizeData[i / timeUnit] = null;
        }
      }
      const data = fullSizeData.map((point, index) => {
        const isMainframe = (point != null && mainFrames.current.indexOf(index * timeUnit) > -1)
        var value = isMainframe ? point : 101;
        var hexValue = value.toString(16);
        var valueStr = value > 15 ? hexValue : `0${hexValue}`
        return valueStr;
      })
      const compressedData = data.reduce((a, b) => `${a}${b}`)
      const handyKey = await handy.getStoredKey()
      axios.post(`${baseURL}/script/generate_csv`,
        {
          data: compressedData,
          duration,
          handyId: handyKey,
        }).then((response) => {
          resolve(response.data);
        });
    });
  }

  function startRecording() {
    setVideoSource()
    setPlaying(false)
    setLoading(true)
    setPaused(false)
    countDownAndRun(() => {
      setCountDownLabel()
      setRecording(true)
      setLoading(false)
      startInterval()
    })
    document.addEventListener("keydown", keyPress, false);
  }

  async function startPlaying() {
    setLoading(true)
    if (handyEnabled && handyConnected) {
      const videoPlayer = videoPlayerElementRef.current
      if (videoSource != null) {
        countDownAndplay();
      } else {
        const replayData = await getCsvScriptReplayData();
        await handy.setScript(replayData.csvUrl);
        setVideoSource(replayData.videoUrl)
        videoPlayer.setAttribute("src", replayData.videoUrl)
        videoPlayer.addEventListener('loadeddata', function () {
          countDownAndplay();
        });
      }
    } else {
      countDownAndplay();
    }
  }

  function countDownAndplay() {
    countDownAndRun(() => {
      setCountDownLabel()
      setPlaying(true)
      setPaused(false)
      setLoading(false)
      setCurrentV(0)
      const videoPlayer = videoPlayerElementRef.current
      if (handyEnabled && handyConnected) {
        videoPlayer.play()
      }
      startInterval()
    })
  }

  function countDownAndRun(callback) {
    setTimeout(() => {
      setCountDownLabel("3")
      playBeep1();
    }, 1000)
    setTimeout(() => {
      setCountDownLabel("2")
      playBeep1();
    }, 2000)
    setTimeout(() => {
      setCountDownLabel("1")
      playBeep1();
    }, 3000)
    setTimeout(() => {
      callback();
      playBeep2();
    }, 4000)
  }

  function pausePlaying() {
    setPaused(true)
    stopInterval()
    if (videoSource != null) {
      videoPlayerElementRef.current.pause();
    }
  }

  function stopPlaying() {
    setPaused(false)
    setPlaying(false)
    stopInterval()
    if (videoSource != null) {
      videoPlayerElementRef.current.pause();
    }
  }

  function resumePlaying() {
    setPaused(false)
    startInterval()
  }

  function stopRecording() {
    stopInterval()
    setRecording(false)
    setPositionAtFrame(lastValue.current, true);
    if (videoSource != null) {
      videoPlayerElementRef.current.currentTime = t.current / 1000
    }
    document.removeEventListener("keydown", keyPress, false);
  }

  function rewind() {
    setPlaying(false)
    setCurrentT(0);
    console.log("rewind to 0");
    t.current = 0;
    if (videoSource != null) {
      videoPlayerElementRef.current.currentTime = 0;
    }
  }

  function onSimulatorClicked() {

    if (paused) {
      resumePlaying()
    } else if (playing) {
      stopPlaying()
    } else if (recording) {
      stopRecording()
    } else if (!recording && !loading) {
      startRecording()
    }
  }

  function setPositionAtFrame(value, isMainFrame) {
    const pointTime = parseInt(t.current - timeUnit) + offset;
    const valueInt = parseInt(value);
    if (pointTime < 0) return;
    const mainFrameIndex = mainFrames.current.indexOf(pointTime);
    if (mainFrameIndex > -1 && !isMainFrame) return

    if (mainFrameIndex === -1 && isMainFrame) mainFrames.current.push(pointTime)

    let pointIndex = timestamps.current.indexOf(pointTime);
    if (pointIndex > -1) {
      dataArray.current[pointIndex] = valueInt;
    } else {
      let index = timestamps.current.length - 1;
      for (let i = index; i >= 0; i--) {
        const timestamp = timestamps.current[i];
        if (timestamp < pointTime) {
          index = i;
          break;
        }
      }

      if (index === timestamps.current.length - 1) {
        dataArray.current.push(valueInt)
        timestamps.current.push(pointTime)
      } else {
        dataArray.current = [
          ...dataArray.current.slice(0, index + 1),
          valueInt,
          ...dataArray.current.slice(index + 1)
        ];
        timestamps.current = [
          ...timestamps.current.slice(0, index + 1),
          pointTime,
          ...timestamps.current.slice(index + 1)
        ];
      }
    }

    const len = dataArray.current.length
    if (pointIndex === len - 2) {
      dataArray.current[len - 1] = valueInt;
    }
    if (value != null) lastValue.current = valueInt;
  }

  function onSimulatorPositionChanged(value, isMainFrame) {
    setKeyboardMode(false);
    setPositionAtFrame(value, isMainFrame)
  }

  function onTimelineClicked(tVal) {
    t.current = tVal;
    setCurrentT(tVal)
    if (videoSource != null) {
      videoPlayerElementRef.current.currentTime = Math.floor(tVal / 1000)
    }
  }

  function onSettingsChanged(newDuration, newOffset, newSpeed) {
    if (newDuration !== duration) {
      const lastT = parseInt(newDuration)
      if (newDuration < duration) {
        dataArray.current = [0, 0];
        timestamps.current = [0, lastT];
        mainFrames.current = [0, lastT];
      } else {
        dataArray.current.push(0)
        timestamps.current.push(lastT);
        mainFrames.current.push(lastT);
      }
      t.current = 0;
      setDuration(newDuration)
    }

    if (newOffset !== offset) {
      setOffset(newOffset)
    }

    if (newSpeed !== speed) {
      setSpeed(newSpeed)
    }
    setCurrentT(0)
    setSettingsDialogOpen(false);
  }

  function toggleHandyEnabled() {
    setHandyEnabled(!handyEnabled);
    if (!handyEnabled) {
      if (handyUiElementRef.current.id === "handy-ui") {
        handy.attachUUI();
        handy.on('state', ({ state, change }) => {
          if (state.connected === true && !handyConnected) {
            ReactGA.event({ category: "connect", action: "device_connected" });
          }
          setHandyConnected(state.connected === true)
        });
        handy.setVideoPlayer(videoPlayerElementRef.current);
      }
    } else {
      handy.disconnect()
    }
  }

  function keyPress(e) {
    setKeyboardMode(true);
    let newPosition = 0;
    if (e.keyCode === 38 || e.keyCode === 104) {
      newPosition = 100;
    } else if (e.keyCode === 101) {
      newPosition = 50;
    } else if (e.keyCode === 40 || e.keyCode === 98) {
      newPosition = 0;
    }
    setPositionAtFrame(newPosition, true)
    setCurrentV(newPosition)
  }

  return <div className='main-screen-container'>

    <div className='top-menu'>
      <div id="handy-ui" ref={handyUiElementRef} hidden={!handyEnabled} ></div>
      {!handyEnabled && <ConnectButton onClick={toggleHandyEnabled} />}
      <DonationButton />
      <video hidden id="video-player" width="50%" src={videoSource} controls ref={videoPlayerElementRef}></video>
      <div className='help-button' onClick={() => {
        ReactGA.event({ category: "help", action: "help_button_clicked" });
        setHelpDialogOpen(true)
      }}>
        <img src={help} alt="tutorial" height={40} width={40} />
      </div>
    </div>

    <div className='title-container' >
      <h1>Funscript.cz</h1>
      <h5>Scripting made easy</h5>
    </div>
    <div className='spacer'></div>
    <Simulator
      label={countDownLabel}
      playing={playing}
      paused={paused}
      recording={recording}
      keyboardMode={keyboardlMode}
      positionSim={currentV}
      onClick={onSimulatorClicked}
      onChange={onSimulatorPositionChanged}
    />

    <div className='spacer'></div>
    <Controls
      playing={playing}
      paused={paused}
      recording={recording}
      loading={loading}
      onPlayClicked={startPlaying}
      onPauseClicked={pausePlaying}
      onRecordClicked={startRecording}
      onStopClicked={stopRecording}
      onRewindClicked={rewind}
      onSettingsClicked={() => setSettingsDialogOpen(true)}
      onDownloadClicked={() => setDownloadDialogOpen(true)}
    />
    <Timeline
      timeUnit={timeUnit}
      duration={duration}
      t={t.current}
      onClick={onTimelineClicked}
      chartData={{ timestamps: timestamps.current, values: dataArray.current }} />

    {downloadDialogOpen &&
      <DownloadDialog dataArray={dataArray.current}
        timestamps={timestamps.current}
        mainFrames={mainFrames.current}
        duration={duration}
        timeUnit={timeUnit}
        onCancelClicked={() => setDownloadDialogOpen(false)} />}

    {settingsDialogOpen &&
      <SettingsDialog dataArray={dataArray.current}
        timestamps={timestamps.current}
        mainFrames={mainFrames.current}
        duration={duration}
        offset={offset}
        speed={speed}
        timeUnit={timeUnit}
        onSaveClicked={onSettingsChanged}
        onCancelClicked={() => setSettingsDialogOpen(false)} />}

    {helpDialogOpen && <HelpDialog onCloseClicked={() => setHelpDialogOpen(false)} />}
  </div>
};