import React from 'react';
import "./DonationButton.css"
import ReactGA from "react-ga4";


export default function DonationButton() {

  const handleClick = () => {
    ReactGA.event({ category: "donation", action: "donate_button_clicked" });
  }

  return <a href="https://www.buymeacoffee.com/funscript" style={{ "textDecoration": "none" }} className='donation-button-link' target='_blank' rel="noreferrer">
    <div className='donation-button-container' onClick={handleClick}>
      <div className='donation-button-icon'>🍺</div>
      <div className='donation-button-text'>Buy me a beer</div>
    </div>
  </a>
};