import React from 'react';


import "./HelpDialog.css"


export default function HelpDialog(props) {
  const { onCloseClicked } = props;

  return <div className='dialog-bg'>
    <div className="dialog-container">
      <h2>Tutorial</h2>
      <p>How to create scripts</p>

      <div className='video-container'>
        <video src="/video/funscript-tutorial.mp4" width={"100%"} autoplay controls></video>
      </div>

      <div className='button-container'>
        <button type="button" className="btn btn-outline-secondary" onClick={onCloseClicked}>Close</button>
      </div>
    </div>
  </div>
};

