import React, { useState } from 'react';


import "./SettingsDialog.css"


export default function SettingsDialog(props) {
  const { onSaveClicked, onCancelClicked } = props;
  const [duration, setDuration] = useState(getTimeStr(props.duration))
  const [offset, setOffset] = useState(`${props.offset}`)
  const [speed, sedSpeed] = useState(props.speed)


  function getTimeStr(time) {
    const minutes = parseInt(time / 60000)
    const seconds = parseInt((time - (60000 * minutes)) / 1000)

    const minutesStr = minutes > 9 ? minutes : `0${minutes}`
    const secondsStr = seconds > 9 ? seconds : `0${seconds}`

    return `${minutesStr}:${secondsStr}`;
  }

  const handleOnSave = () => {
    const mins = parseInt(duration.split(":")[0])
    const secs = parseInt(duration.split(":")[1])
    const newDuration = mins * 60000 + secs * 1000;
    if (newDuration > 0 && newDuration < 9000000) {
      onSaveClicked(newDuration, parseInt(offset), speed)
    }
  }

  return <div className='dialog-bg'>
    <div className="dialog-container">
      <h2>Settings</h2>
      <p>Configure your script recording settings</p>

      <div className="mb-3">
        <div className="input-group">
          <span className="input-group-text" id="basic-addon3">Duration</span>
          <input type="text" value={duration} className="form-control" aria-describedby="basic-addon3 basic-addon4" onChange={(e) => setDuration(e.target.value)} />
        </div>
      </div>
      <div className="mb-3">
        <div className="input-group">
          <span className="input-group-text" id="basic-addon3">Offset (ms)</span>
          <input type="text" value={offset} className="form-control" aria-describedby="basic-addon3 basic-addon4" onChange={(e) => setOffset(e.target.value)} />
        </div>
      </div>
      <div className="mb-3">
        <div className="input-group slider-container" >
          <span className="input-group-text" id="basic-addon3">Speed</span>
          <h6 className='speed-label'>0.5 x</h6>
          <input id='speed-range' type="range" className="form-range" min="0.5" max="1" step="0.5" value={speed} onChange={(e) => { sedSpeed(Number(e.target.value)) }} />
          <h6 className='speed-label'>1 x</h6>
        </div>
      </div>
      <div className='button-container'>
        <button type="button" className="btn btn-outline-secondary" onClick={onCancelClicked}>Cancel</button>
        <button type="button" className="btn btn-primary" onClick={handleOnSave}>Save</button>
      </div>
    </div>
  </div>
};

