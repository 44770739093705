import React from 'react';
import "./Controls.css"

import play from "../../../../../assets/images/icons/play.svg";
import pause from "../../../../../assets/images/icons/pause.svg";
import stop from "../../../../../assets/images/icons/stop.svg";
import rewind from "../../../../../assets/images/icons/rewind.svg";
import record from "../../../../../assets/images/icons/record.svg";
import download from "../../../../../assets/images/icons/download.svg";
import settings from "../../../../../assets/images/icons/settings.svg";


export default function Controls(props) {
  const { playing, recording, paused, loading, onPlayClicked, onRecordClicked, onStopClicked, onRewindClicked, onPauseClicked, onDownloadClicked, onSettingsClicked } = props

  const showMainButtons = ((!playing || paused) && !recording && !loading);

  return <div className='controls-container'>
    {showMainButtons && <div className='control-button' onClick={onRewindClicked}>
      <img src={rewind} alt="rewind" width={30} />
    </div>}
    {showMainButtons && <div className='control-button' onClick={onRecordClicked}>
      <img src={record} alt="record" width={50} />
    </div>}
    {showMainButtons && <div className='control-button' onClick={onPlayClicked}>
      <img src={play} alt="play" width={30} />
    </div>}
    {(playing && !paused) && <div className='control-button' onClick={onPauseClicked}>
      <img src={pause} alt="pause" width={30} />
    </div>}
    {recording && <div className='control-button' onClick={onStopClicked}>
      <img src={stop} alt="stop" width={30} />
    </div>}
    {showMainButtons && <div className='control-button' onClick={onDownloadClicked}>
      <img src={download} alt="download" width={30} />
    </div>}
    {showMainButtons && <div className='control-button' onClick={onSettingsClicked}>
      <img src={settings} alt="settings" width={30} />
    </div>}
  </div>
};
