import React from 'react';
import "./ConnectButton.css"
import ReactGA from "react-ga4";


export default function ConnectButton(props) {
  const { onClick } = props;
  const handleClick = () => {
    ReactGA.event({ category: "connect", action: "connect_button_clicked" });
    onClick()
  }

  return <div className='connect-button-container' onClick={handleClick}>
    <div className='connect-button-icon'>🎚️</div>
    <div className='connect-button-text'>Connect</div>
  </div>
};