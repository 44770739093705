import "./Simulator.css"
import machineImage from "../../../../../assets/images/machine.png";
import sliderImage from "../../../../../assets/images/slider.png";
import { useState } from "react";

const isTouchDevice = () => ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);

export default function Simulator(props) {
  const [position, setPosition] = useState(0)
  const [direction, setDirection] = useState(1)
  const { recording, keyboardMode, playing, paused, positionSim, label, onClick, onChange } = props;

  function handleMouseMove(ev) {
    if (!recording) return;
    const containerHeight = ev.currentTarget.offsetHeight;
    const y = (ev.clientY - ev.currentTarget.offsetTop);
    if (y < 0 || y > containerHeight) return;
    const percentageY = y * 100 / containerHeight
    const value = (100 - percentageY)
    processNewPosition(value)
  }
  function handleTouchMove(ev) {
    if (!recording) return;
    var myLocation = ev.changedTouches[0];
    var mainTarget = document.getElementById("simulator")
    const containerHeight = mainTarget.offsetHeight;
    const y = (myLocation.clientY - mainTarget.offsetTop);
    if (y < 0 || y > containerHeight) return;
    const percentageY = y * 100 / containerHeight
    const value = (100 - percentageY)
    processNewPosition(value)
  }

  function processNewPosition(newPosition) {
    const stopUpGoingDown = direction === 1 && newPosition < position;
    const stopDownGoingUp = direction === -1 && newPosition > position;
    setPosition(newPosition)
    let isMainFrame = false;
    if (stopUpGoingDown || stopDownGoingUp || position == null) {
      isMainFrame = true;
      setDirection(direction * -1);
    }
    onChange(newPosition, isMainFrame)
  }

  function getStatus() {
    if (recording) return "recording";
    if (playing && !paused) return "playing";
    return "iddle";
  }

  function getPosition() {
    if (playing || paused || keyboardMode) return positionSim;
    return position;
  }

  const isTouch = isTouchDevice()
  return <div draggable={false} id="simulator" className={"simulator-container " + getStatus()}
    onMouseMove={(recording && !isTouch) ? (ev) => handleMouseMove(ev) : null}
    onTouchMove={(recording && isTouch) ? (ev) => handleTouchMove(ev) : null}
    onClick={onClick} >
    <img src={machineImage} alt="machine" className="machine-image" />
    <img src={sliderImage} alt="machine" className="slider-image" style={{ bottom: `${getPosition() / 2}%` }} />

    {
      label &&
      <div className="simulator-label-container">
        <div className="simulator-label">{label}</div>
      </div>
    }
    {
      recording &&
      <div className="recording-label-container">
        <div className="recording-dot"></div>
        <div>Recording</div>
      </div>
    }
  </div >

};
