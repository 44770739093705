import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MainScreen from './view/screens/main/MainScreen';
import ReactGA from "react-ga4";


const root = ReactDOM.createRoot(document.getElementById('root'));
ReactGA.initialize("G-X1R13QZB94");
root.render(
  <React.StrictMode>
    <MainScreen />
  </React.StrictMode>
);
