import React, { useState } from 'react';
import axios from "axios";
import ReactGA from "react-ga4";

import Lottie from "lottie-react";
import loadingAnimation from "../../../../../assets/animations/loading.json";
import successAnimation from "../../../../../assets/animations/success.json";


import "./DownloadDialog.css"

const baseURL = "https://api.funscript.cz";

export default function DownloadDialog(props) {
  const { dataArray, mainFrames, timestamps, timeUnit, duration, onCancelClicked } = props;
  const [title, setTitle] = useState("")
  const [url, setUrl] = useState("")
  const [performers, setPerformers] = useState("")
  const [tags, setTags] = useState("")
  const [creator, setCreator] = useState("")

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const onDownloadClicked = () => {
    setLoading(true);
    const fullSizeData = [];
    fullSizeData[0] = 0;
    fullSizeData[(parseInt(duration / timeUnit) - 1)] = 0;
    for (let i = 0; i < duration; i += timeUnit) {
      const index = timestamps.indexOf(i)
      if (index > -1) {
        fullSizeData[i / timeUnit] = dataArray[index];
      } else {
        fullSizeData[i / timeUnit] = null;
      }
    }
    const data = fullSizeData.map((point, index) => {
      const isMainframe = (point != null && mainFrames.indexOf(index * timeUnit) > -1)
      var value = isMainframe ? parseInt(point) : 101;
      var hexValue = value.toString(16);
      var valueStr = value > 15 ? hexValue : `0${hexValue}`
      return valueStr;
    })

    const compressedData = data.reduce((a, b) => `${a}${b}`)
    axios.post(`${baseURL}/script/download`,
      {
        data: compressedData,
        duration,
        title,
        url,
        performers,
        tags,
        creator
      }).then((response) => {
        downloadScript(response.data.content)
      });
  }

  const downloadScript = (data) => {
    const element = document.createElement("a");
    const file = new Blob([data], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = "script.funscript";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    ReactGA.event({ category: "download", action: "script_download", value: duration, label: creator });
    setLoading(false);
    setSuccess(true);
  }

  if (loading) {
    return <div className='dialog-bg'>
      <div className="dialog-container-centered">
        <div className="loading-animation-container">
          <Lottie animationData={loadingAnimation} loop={true} />
        </div>
      </div>
    </div>
  }

  if (success) {
    return <div className='dialog-bg'>
      <div className="dialog-container-centered">
        <div className="success-animation-container">
          <Lottie animationData={successAnimation} loop={false} />
        </div>
        <h1>Script downloaded</h1>
        <button type="button" className="btn btn-primary" onClick={onCancelClicked}>OK</button>
      </div>
    </div>
  }


  return <div className='dialog-bg'>
    <div className="dialog-container">
      <h2>Download</h2>
      <p>add script meta data</p>

      <div className="mb-3">
        <div className="input-group">
          <span className="input-group-text" id="basic-addon3">Video title</span>
          <input type="text" className="form-control" aria-describedby="basic-addon3 basic-addon4" onChange={(e) => setTitle(e.target.value)} />
        </div>
      </div>
      <div className="mb-3">
        <div className="input-group">
          <span className="input-group-text" id="basic-addon3">Video url</span>
          <input type="text" className="form-control" aria-describedby="basic-addon3 basic-addon4" onChange={(e) => setUrl(e.target.value)} />
        </div>
      </div>
      <div className="mb-3">
        <div className="input-group">
          <span className="input-group-text" id="basic-addon3">Performers</span>
          <input type="text" className="form-control" aria-describedby="basic-addon3 basic-addon4" placeholder='comma-separated' onChange={(e) => setPerformers(e.target.value)} />
        </div>
      </div>
      <div className="mb-3">
        <div className="input-group">
          <span className="input-group-text" id="basic-addon3">Tags</span>
          <input type="text" className="form-control" aria-describedby="basic-addon3 basic-addon4" placeholder='comma-separated' onChange={(e) => setTags(e.target.value)} />
        </div>
      </div>
      <div className="mb-3">
        <div className="input-group">
          <span className="input-group-text" id="basic-addon3">Creator</span>
          <input type="text" className="form-control" aria-describedby="basic-addon3 basic-addon4" onChange={(e) => setCreator(e.target.value)} />
        </div>
      </div>
      <div className='button-container'>
        <button type="button" className="btn btn-outline-secondary" onClick={onCancelClicked}>Cancel</button>
        <button type="button" className="btn btn-primary" onClick={onDownloadClicked}>Download</button>
      </div>
    </div>
  </div>
};

