import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";


Chart.register(...registerables);


export default function ScriptChart(props) {
  const { dataArray, timeline, duration } = props;
  const tickCount = parseInt(duration / 60000) + 1;
  return (
    <Line
      data={{
        labels: timeline,
        datasets: [
          {
            label: "t",
            data: dataArray,
            fill: "start",
            borderWidth: 1,
            lineTension: 0.2
          },
        ],
      }}
      options={{
        animation: { duration: 0 },
        bezierCurve: true,
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          autoPadding: false,
          padding: { left: 0, right: 0, top: 10, bottom: 0 }
        },
        spanGaps: true,
        elements: {
          point: { radius: 0, hoverRadius: 0 },
          line: {
            backgroundColor: "#303223",
            borderColor: "#e4d870",
          },
        },
        plugins: {
          legend: { display: false },
        },
        scales: {
          x: {
            type: "time",
            // display: false,
            border: { display: false },
            ticks: { display: false, maxTicksLimit: tickCount },
            grid: { display: true, color: "#C1C1C1", drawTicks: true, lineWidth: 0.2, maxTicksLimit: 2 },
          },
          y: {
            display: false,
            max: 100,
            border: { display: false },
            ticks: { display: false },
            grid: { display: false },
          },
        },
      }}
    />
  );
}
