import React, { useState } from 'react';
import "./Timeline.css"
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import ScriptChart from './components/ScriptChart';


export default function Timeline(props) {
  const [shadowT, setShadowT] = useState()
  const [shadowTPixels, setShadowTPixels] = useState()

  const { duration, t, chartData, onClick, onMouseLeave, onMouseEnter } = props
  const { width } = useWindowDimensions();

  function handleMouseMove(ev) {
    const containerWidth = ev.currentTarget.offsetWidth;
    const x = (ev.clientX - ev.currentTarget.offsetLeft);
    if (x < 0 || x > containerWidth) return;
    const percentageX = x / containerWidth
    const value = percentageX * duration;
    setShadowTPixels(x)
    setShadowT(value)
  }

  function handleOnClicked(ev) {
    const y = (ev.clientY - ev.currentTarget.offsetTop);
    if (y < 0) return;

    const containerWidth = ev.currentTarget.offsetWidth;
    const x = (ev.clientX - ev.currentTarget.offsetLeft);

    const tClicked = x * duration / containerWidth;
    onClick(tClicked)

  }

  function handleMouseLeave(ev) {
    setShadowTPixels();
    setShadowT();
    if (onMouseLeave) onMouseLeave();
  }

  function handleMouseEnter(ev) {
    if (onMouseEnter) onMouseEnter();
  }


  function getCurrentTPixels() {
    const containerWidth = width * 0.94;
    const left = t * containerWidth / duration;
    return left;
  }

  function getTime(time) {
    const minutes = parseInt(time / 60000)
    const seconds = parseInt((time - (60000 * minutes)) / 1000)

    const minutesStr = minutes > 9 ? minutes : `0${minutes}`
    const secondsStr = seconds > 9 ? seconds : `0${seconds}`

    return `${minutesStr}:${secondsStr}`;
  }

  return <div className='timeline-container'
    onMouseMove={handleMouseMove}
    onClick={handleOnClicked}
    onMouseLeave={handleMouseLeave}
    onMouseEnter={handleMouseEnter}
  >
    {shadowTPixels != null ? <div className='mouse-shadow-line' style={{ left: shadowTPixels }}></div> : null}
    {shadowTPixels != null ? <div className='shadow-t-label' style={{ left: shadowTPixels }}>{getTime(shadowT)}</div> : null}

    <div className="duration-label-container">
      <div className='duration-label'>{getTime(duration)}</div>
    </div>

    <div className='current-t-line' style={{ left: getCurrentTPixels() }}></div>
    <div className='current-t-label' style={{ left: getCurrentTPixels() }}>{getTime(t)}</div>

    <ScriptChart duration={duration} timeline={chartData.timestamps} dataArray={chartData.values} />
  </div>

};
